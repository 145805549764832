import React from 'react'

import { List } from 'reactstrap'
import { privacyPolicyContent } from './privacyPolicyContent'

import './PrivacyPolicy.scss'
const PrivacyPolicy = ({
    heading='',
}) => {
    return (
        <div
            className='privacyPoliciesContent'
        >
            { heading && <h1>{heading}</h1>}
            <div
                className='privacyPoliciesContentContainer'
            >
                {
                    privacyPolicyContent.map(item => {
                        return(
                            <span>
                                {item.headline && <h2>{item.headline}</h2>}
                                {
                                    item.content.map(description => <li dangerouslySetInnerHTML={{__html: description}}/>)
                                }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PrivacyPolicy
