export const privacyPolicyContent = [
    {
        headline: '',
        content: [
            `DNNae does not store any personal information not related to a "campaign". All information stored is used for allowing the user to take the next steps and make better decisions on their own campaigns.`,

            `Similar to other popular services such as GMAIL, Linkedin, etc; No personal information is ever shared with any other entity (businesses, individuals or governments), nor do we ever use any personal information to sell any kind of services.`,

            `No messages (EMAIL/Linkedin/Messaging) are ever stored, except for the ones tied to a particular campaign. Only messages related to a "campaign" are stored for the user to review in a consolidated location for the best user experience and ease of use.`,

            `Personal data/information may be used to better improve our Natural Language Understanding (NLU) models to help DNNae provide a continuously better service.`,

            `DNNae will collect summaries of LinkedIn Profile Visits to be able to deliver the functionality of the extension.`,

            `The profile summaries can be used to deliver future value-added services by DNNae.`,

            `All data collected for a user will be erased 1 month after requested by the user via our support channel.`,

            `DNNae does not collect data from any other website or any other activity.`,

        ],
        index: 1
    },
]